import React, { useMemo, useState } from "react";
import {UIButton, UIModal, UISelect, UITextarea} from "../../../Ui";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { useCustomerData } from "../../../Customer";
import { useAssociateReportProblemState } from "../../state";
import { useForm } from "react-hook-form";
import { useYup } from '@yup/context';

type FormData = {
    code: string;
    description: string;
}

type ProblemCode = {
    problem_type: string;
    problem_code: string;
}

type ProblemsAndCodes = {
    [key: string]: ProblemCode;
}

export const ReportAProblem = () => {
    const { isLoggedIn, isAssociate } = useCustomerData();
    const { formatMessage } = useIntl();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { reportProblem } = useAssociateReportProblemState();
    const { errorMessage, yup, resolver } = useYup();

    const problemsAndCodes = window?.appConfig?.associate?.problemsAndCodes as ProblemsAndCodes | undefined;

    const schema = yup.object().shape({
        code: yup.string().required(formatMessage({
            id: 'associate.contact.problem.type.required',
            defaultMessage: 'Please select a problem type'
        })),
        description: yup.string()
            .required(formatMessage({
                id: 'associate.contact.problem.description.required',
                defaultMessage: 'Description is required'
            }))
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: resolver(schema)
    });

    const problemOptions = useMemo(() => {
        if (!problemsAndCodes) return [];

        return Object.values(problemsAndCodes).map(({ problem_type, problem_code }) => ({
            value: problem_code,
            label: problem_type
        }));
    }, [problemsAndCodes]);

    if (!isLoggedIn || !isAssociate || !problemsAndCodes) {
        return null;
    }

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
        reset();
    };

    const getProblemTypeByCode = (code: string): string => {
        if (!problemsAndCodes) return code;

        const problemEntry = Object.values(problemsAndCodes).find(
            (problem: ProblemCode) => problem.problem_code === code
        );
        return problemEntry?.problem_type || code;
    };

    const onSubmit = (data: FormData) => {
        reportProblem.mutate({
            code: data.code,
            type: getProblemTypeByCode(data.code),
            description: data.description,
            url: window.location.href
        }, {
            onSuccess: () => {
                handleClose();
            }
        });
    };

    return (
        <div>
            <div>
                <div className='ec-fixed ec-right-0 ec-top-1/2 ec-z-90'>
                    <button
                        onClick={handleOpen}
                        className={clsx(
                            'ec-absolute ec-bottom-0 ec-w-max [transform:rotate(-90deg)_translateX(-50%)]',
                            'ec-origin-bottom-left ec-rounded-tr-md ec-rounded-tl-md ec-flex ec-px-3 ec-py-2',
                            'ec-text-white ec-bg-red hover:ec-bg-red-dark ec-cursor-pointer ec-items-center'
                        )}>
                        <p className='ec-text-xs ec-m-0 ec-uppercase ec-mx-2'>
                            <FormattedMessage id='associate.contact.button' defaultMessage='Report a Problem'/>
                        </p>
                    </button>
                </div>
            </div>
            <UIModal
                open={isOpen}
                onClose={handleClose}
                title={formatMessage({id: 'associate.contact.title', defaultMessage: 'Report a Problem'})}
                slide>
                <form onSubmit={handleSubmit(onSubmit)} className='ec-flex ec-flex-col ec-gap-4'>
                    <div className='ec-flex ec-flex-col ec-gap-4 ec-w-full'>
                        <UISelect
                            label={formatMessage({id: 'associate.contact.problem.type', defaultMessage: 'Problem Type'})}
                            options={problemOptions}
                            emptyOption={true}
                            error={errorMessage(errors, 'code')}
                            required
                            register={register('code')}
                        />
                        <UITextarea
                            label={formatMessage({id: 'associate.contact.problem.description', defaultMessage: 'Description'})}
                            error={errorMessage(errors, 'description')}
                            required
                            register={register('description')}
                        />
                    </div>
                    <UIButton
                        type="submit"
                        disabled={reportProblem.isLoading}
                    >
                        <FormattedMessage id="reportProblem.button.send" defaultMessage="Report a Problem" />
                    </UIButton>
                </form>
            </UIModal>
        </div>
    );
};

ReportAProblem.whyDidYouRender = true;
