import React, {createContext, useCallback, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useMapsLibrary} from "@vis.gl/react-google-maps";
import {useInventoryFinderState } from './InventoryFinderStateProvider'
import { useMap } from '@map/hooks'

type GoogleMapsProviderType = {
    isLoaded: any;
    useSelectedBranchLocation: string;
    findBranchLabel: string;
    searchInputRef: any;
    onMapIdle: () => void;
    handleUseMyCurrent: () => void;
    handleUseMyCurrentBranchLocation: () => void;
    mapContainerRef: any;
    mapContainerStyle: any;
    setMapContainerStyle: any;
    mapRef: any;
    currentLocation: { lat: number; lng: number } | null;
    setIsAllBranchMapMarkersVisible: any;
    isGeoLocationEnabled: boolean;
    isShowMobileLargeMap: boolean;
    setIsShowMobileLargeMap: any;
}

const GoogleMapsStateProvider = createContext<GoogleMapsProviderType | null>(null);

export const GoogleMapsProvider = ({children}) => {

    const [currentLocation, setCurrentLocation] = useState<{ lat: number; lng: number } | null>(null);
    const [isAllBranchMapMarkersVisible, setIsAllBranchMapMarkersVisible] = useState(false);
    const [isGeoLocationEnabled, setIsGeoLocationEnabled] = useState(false);
    const [isShowMobileLargeMap, setIsShowMobileLargeMap] = useState(false);

    const { map, selectMarker } = useMap();
    const coreLibrary = useMapsLibrary('core');

    const {
        currentBranch,
        branches,
        setDistances,
        setVisibleMarkers,
        setCenterMap,
    } = useInventoryFinderState();

    const searchInputRef = useRef(null);
    const mapRef = useRef(null);
    const intl = useIntl();

    const mapContainerRef = useRef(null);
    const [mapContainerStyle, setMapContainerStyle] = useState({
        height: '100%',
        backgroundColor: 'grey',
    });

    //review this
    const isLoaded = map ? map : false;

    const findBranchLabel = intl.formatMessage({
        id: 'branchMap.findBranchLabel',
        defaultMessage: 'Find Branch'
    });

    const useSelectedBranchLocation = intl.formatMessage({
        id: 'branchMap.useSelectedBranchLocation',
        defaultMessage: 'Use my selected branch location'
    });

    const getDistance = (pointA, pointB) => {

        if (!coreLibrary) {
            return 0;
        }

        const {LatLng} = coreLibrary;

        const latLngA = new LatLng(pointA.lat, pointA.lng);
        const latLngB = new LatLng(pointB.latitude, pointB.longitude);

        return google.maps.geometry.spherical.computeDistanceBetween(latLngA, latLngB);
    };

    const getBranchesDistance = (point, branch) => {
        if(!point || !branch) {
            return null;
        }

        if (!coreLibrary) {
            return { [branch.branch_id]: 0 };
        }

        const {LatLng} = coreLibrary;

        const pointLat = point.lat || point.latitude;
        const pointLng = point.lng || point.longitude;

        const pointLatLng = new LatLng(pointLat, pointLng);
        const branchLatLng = new LatLng(branch.latitude, branch.longitude);
        const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(pointLatLng, branchLatLng);
        const distanceInMiles = distanceInMeters / 1609.34;
        const branchId = branch.branch_id;
        const branchDistance = formatDistance(distanceInMiles);
        const result = {};
        result[branchId] = branchDistance;
        return result;
    };

    const formatDistance = value => {
        if (value < 1) {
            return value.toFixed(2);
        } else if (value > 10) {
            return Math.floor(value);
        }
        return value.toFixed(1);
    };

    const updateVisibleMarkers = useCallback(() => {
        if (!map || !branches.length) return;

        const {LatLng} = coreLibrary;

        const newVisibleMarkers = branches.filter((branch) => {
            const currentBounds = map.getBounds();
            if (!currentBounds) return false;

            const branchLatLng = new LatLng(branch.latitude, branch.longitude);
            return currentBounds.contains(branchLatLng);
        });

        setVisibleMarkers(newVisibleMarkers);
    }, [map, branches, coreLibrary]);

    const onMapIdle = useCallback(() => {
        updateVisibleMarkers();
    }, [updateVisibleMarkers]);

    const handleUseMyCurrentBranchLocation = () => {
        const location = {
            lat: currentBranch.latitude,
            lng: currentBranch.longitude
        }
        setCurrentLocation(location);
        selectMarker({
            id: currentBranch.branch_id,
            position: location
        })
    };

    const handleUseMyCurrent = () => {
            void getCurrentLocation();
    };

    const getCurrentLocation = async () => {
        if (!navigator.geolocation) {
            console.log('Geolocation is not supported by your browser');
            setDefaultLocation();
            return;
        }

        try {
            const permission = await navigator.permissions.query({ name: 'geolocation' });

            if (permission.state === 'denied') {
                console.log('Geolocation permission denied');
                setDefaultLocation();
                return;
            }

            try {
                const position = await new Promise<GeolocationPosition>((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });

                const location = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                setIsGeoLocationEnabled(true);
                setCurrentLocation(location);

                if (map && coreLibrary && searchInputRef.current) {
                    const { LatLng } = coreLibrary;

                    const geocoder = new google.maps.Geocoder();
                    try {
                        const result = await geocoder.geocode({
                            location: location
                        });

                        if (result.results[0] && searchInputRef.current) {
                            searchInputRef.current.value = result.results[0].formatted_address;
                        }
                    } catch (geocodeError) {
                        console.error('Geocoding error:', geocodeError);
                    }

                    let closestBranch = currentBranch;
                    let shortestDistance = getDistance(location, closestBranch);

                    branches.forEach(branch => {
                        const distance = getDistance(location, branch);
                        if (distance < shortestDistance) {
                            shortestDistance = distance;
                            closestBranch = branch;
                        }
                    });

                    if(!isAllBranchMapMarkersVisible){
                        const bounds = new google.maps.LatLngBounds();
                        bounds.extend(new LatLng(location.lat, location.lng));
                        bounds.extend(new LatLng(closestBranch.latitude, closestBranch.longitude));
                        map.fitBounds(bounds);
                        return;
                    }
                    map.setZoom(5);
                }
            } catch (error) {
                console.error('Error getting current location:', error);
                setDefaultLocation();
            }
        } catch (error) {
            console.error('Error checking geolocation permission:', error);
            setDefaultLocation();
        }
    };

    useEffect(() => {
        if (map && branches.length > 0) {
            const { LatLng } = coreLibrary;
            const visibleBranches = branches.filter((branch) => {
                const currentBounds = map.getBounds();
                if (!currentBounds) return false;

                const branchLatLng = new LatLng(branch.latitude, branch.longitude);
                return currentBounds.contains(branchLatLng);
            });

            if(visibleBranches.length === 1) {

                selectMarker({
                    id: visibleBranches[0].branch_id,
                    position: { lat: visibleBranches[0].latitude, lng: visibleBranches[0].longitude }
                })
            }
        }
    }, [map, branches, currentLocation]);

    const setDefaultLocation = () => {
        if (currentBranch) {
            const defaultLocation = {
                lat: currentBranch.latitude,
                lng: currentBranch.longitude
            };
            setCurrentLocation(defaultLocation);

            if (map && coreLibrary) {
                const { LatLng } = coreLibrary;

                const bounds = new google.maps.LatLngBounds();
                bounds.extend(new LatLng(defaultLocation.lat, defaultLocation.lng));

                if(!isAllBranchMapMarkersVisible){
                    map.fitBounds(bounds);
                    map.setZoom(10);
                    return;
                }
                map.setZoom(5);
            }
        }
    };

    useEffect(() => {
        if (isLoaded && map && coreLibrary && searchInputRef.current) {
            const {LatLng, LatLngBounds} = coreLibrary;
            const searchOptions = {
                componentRestrictions: { country: 'us' },
            };

            //@ts-ignore
            const searchBox = new window.google.maps.places.Autocomplete(searchInputRef.current, searchOptions);

            searchBox.addListener('place_changed', () => {
                const places = searchBox.getPlace();

                if (!places && !places.geometry.location) return;

                const location = {
                    lat: places.geometry.location.lat(),
                    lng: places.geometry.location.lng()
                };

                setCurrentLocation(location);

                let closestBranch = currentBranch;
                let shortestDistance = getDistance(location, closestBranch);
                let visibleBranches = [];

                branches.forEach(branch => {
                    const distance = getDistance(location, branch);
                    if (distance < shortestDistance) {
                        shortestDistance = distance;
                        closestBranch = branch;
                    }

                    if (distance / 1609.34 <= 50) {
                        visibleBranches.push(branch);
                    }
                });

                const bounds = new LatLngBounds();

                if (visibleBranches.length === 1) {
                    const branch = visibleBranches[0];
                    map.setCenter({ lat: branch.latitude, lng: branch.longitude });
                    map.setZoom(10);
                } else {
                    bounds.extend(new LatLng(location.lat, location.lng));
                    bounds.extend(new LatLng(closestBranch.latitude, closestBranch.longitude));
                    map.fitBounds(bounds);
                }
            });
        }
    }, [isLoaded, map, branches, coreLibrary]);

    useEffect(() => {
        if (currentBranch) {
            setCenterMap(currentBranch);
        }
    }, [currentBranch]);

    useEffect(() => {
        if (branches.length > 0 && isLoaded) {
            const referencePoint = currentLocation ? currentLocation : currentBranch;
            if (referencePoint) {
                const distances = branches.reduce((acc, branch) => {
                    const branchDistance = getBranchesDistance(referencePoint, branch);
                    return { ...acc, ...branchDistance };
                }, {});

                setDistances(distances);
            }
        }
    }, [branches, isLoaded, currentBranch, currentLocation, isGeoLocationEnabled]);

    useEffect(() => {
        if (isLoaded && map && branches.length > 0) {
            void getCurrentLocation();
        }
    }, [isLoaded, map, branches]);

    return (
        <GoogleMapsStateProvider.Provider value={{
            isLoaded,
            useSelectedBranchLocation,
            findBranchLabel,
            searchInputRef,
            onMapIdle,
            handleUseMyCurrent,
            handleUseMyCurrentBranchLocation,
            mapContainerRef,
            mapContainerStyle,
            setMapContainerStyle,
            mapRef,
            currentLocation,
            setIsAllBranchMapMarkersVisible,
            isGeoLocationEnabled,
            isShowMobileLargeMap,
            setIsShowMobileLargeMap
        }}>
            {children}
        </GoogleMapsStateProvider.Provider>
    )
};

export const useGoogleMapsState = () => {
    const context = React.useContext(GoogleMapsStateProvider);
    if (context === undefined) {
        throw new Error('useGoogleMapsState must be used within a GoogleMapsStateProvider')
    }
    return context;
};
