import { urlBuilder } from '@utils'
import { CUSTOMER_LOGIN_PATH } from '../config'

const REFERER_KEY = 'referer'

/* @see src/vendor/magento/framework/Url/Encoder.php */
const characterMap = [
    ['+', '-'],
    ['/', '_'],
    ['=', '~']
]

export const getLoginUrl = (): URL => {
    const url = urlBuilder().setPath(CUSTOMER_LOGIN_PATH).getUrl()

    url.searchParams.append(REFERER_KEY, getLoginUrlReferer())
    return url
}

const getLoginUrlReferer = () => (
    characterMap.reduce((url, [from, to]) => (
        url.replace(new RegExp('\\' + from, 'g'), to)
    ), btoa(window.location.href))
)
