import React, { useState, } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { BranchStructure } from "./BranchStructure";
import { useInventoryFinderState } from "../../state";
import { BranchSkeleton } from './Skeleton/BranchSkeleton';

export const MobileNearby = () => {
    const [visibleCount, setVisibleCount] = useState(6);

    const {
        branches,
        distances,
        currentBranch,
        preferredBranch,
        isBranchesLoading,
        isLoadingData,
    } = useInventoryFinderState();

    const intl = useIntl();

    const nearbyBranchesTitle = intl.formatMessage({
        id: 'nearby.branches.title',
        defaultMessage: 'Nearby Branches'
    });

    if (isBranchesLoading || isLoadingData) {
        return (<BranchSkeleton/>)
    }

    const sortedBranches = branches
        .filter(branch =>
            branch.branch_id !== currentBranch?.branch_id &&
            branch.branch_id !== preferredBranch?.branch_id
        )
        .sort((a, b) => distances[a.branch_id] - distances[b.branch_id]);

    const visibleBranches = sortedBranches.slice(0, visibleCount);
    const isShowMoreButtonVisible = visibleBranches.length < sortedBranches.length;

    const loadMoreBranches = () => {
        setVisibleCount(prevCount => prevCount + 5);
    }

    return (
        <div>
            <div>
                <h2>{nearbyBranchesTitle}</h2>
                <BranchStructure data={visibleBranches}/>
                {isShowMoreButtonVisible && (
                    <a
                        className="ec-w-full ec-block ec-text-center ec-uppercase ec-pt-0 ec-pb-5 ec-cursor-pointer"
                        onClick={loadMoreBranches}
                    >
                        <FormattedMessage
                            id="nearby.branches.loadMore"
                            defaultMessage="Load more"
                        />
                    </a>
                )}
            </div>
        </div>
    )
}
