import React from 'react'
import { UIButton } from '@ui/components'
import { SaveCartAction } from '@saveCart/components'
import { useModal } from '@ui/hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { SaveCartProvider, useSaveCart } from '@saveCart/context'
import { useAnalytics } from '@analytics/hooks'

export const SaveCart = () => (
    <SaveCartProvider>
        <SaveCartContent/>
    </SaveCartProvider>
)

export const SaveCartContent = () => {
    const { trackSavedCartSave } = useAnalytics()
    const { isAllowed } = useSaveCart()
    const { formatMessage } = useIntl()
    const modal = useModal()

    if (!isAllowed) return false

    const handleSuccess = () => trackSavedCartSave()

    return (
        <>
            <UIButton variant="text"
                      size="small"
                      fullWidth={true}
                      onClick={modal.handleOpen}>
                <span><FormattedMessage id="cart.save.cart" defaultMessage="Save Cart"/></span>
            </UIButton>
            <SaveCartAction {...modal}
                            onSuccess={handleSuccess}
                            formatMessage={formatMessage}/>
        </>
    )
}
