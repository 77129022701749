import React, {memo, useState} from "react";
import {Selected} from "./Selected";
import {Recent} from "./Recent";
import {Nearby} from "./Nearby";
import {GoogleMap} from "./Map/GoogleMap";
import {Preferred} from "./Preferred";
import {ProductInventory} from "./Product/ProductInventory";
import {useWindowSize} from "@ui/hooks";
import {UIInput, UIModal} from "../../../Ui";
import {useGoogleMapsState, useInventoryFinderState} from "../../state";
import {FormattedMessage, useIntl} from "react-intl";
import {MarkerIcon, StoreFrontIcon} from "../../icons";
import {useCustomerData} from "../../../Customer";
import {isPropsAreEqual} from "../../../../utils";
import {MobileNearby} from "./MobileNearby";

export const Branches = memo(() => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { isLoggedIn } = useCustomerData();
    const {isMobile} = useWindowSize();
    const intl = useIntl();

    const {
        searchInputRef,
        handleUseMyCurrent,
        handleUseMyCurrentBranchLocation,
        isGeoLocationEnabled
    } = useGoogleMapsState()

    const {
        isBranchesLoading,
        isLoadingData,
    } = useInventoryFinderState();

    const findBranchLabel = intl.formatMessage({
        id: 'branchMap.findBranchLabel',
        defaultMessage: 'Find Branch'
    });

    const useSelectedBranchLocation = intl.formatMessage({
        id: 'branchMap.useSelectedBranchLocation',
        defaultMessage: 'Use my selected branch location'
    });

    const useMyLocation = intl.formatMessage({
        id: 'branchMap.useMyLocation',
        defaultMessage: 'Use my location'
    });

    const handleLocationClick = () => {
        if(isGeoLocationEnabled) {
            handleUseMyCurrent()
            return;
        }
        setIsModalOpen(true);
    }

    if (isMobile) {
        return (
            <div>
                <ProductInventory/>
                <div>
                    <div>
                        <Selected/>
                        <Preferred/>
                        <Recent/>
                        <MobileNearby/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="ec-flex ec-flex-col ec-h-full">
            <ProductInventory/>
            <div className={'ec-flex ec-gap-4 ec-h-full'}>
                <div className="ec-w-[55%] ec-relative">
                    <div className="ec-overflow-y-scroll ec-absolute ec-inset-0 ec-pr-3.5">
                        {!isBranchesLoading && !isLoadingData && <div className="relative">
                            <UIInput ref={searchInputRef} label={findBranchLabel}/>
                            {isLoggedIn &&
                                <div>
                                    <div className="ec-flex ec-ec-items-center ec-mt-4">
                                        <a className="ec-text-sm ec-flex ec-space-x-2" onClick={handleUseMyCurrentBranchLocation}>
                                            <StoreFrontIcon/>
                                            <span>{useSelectedBranchLocation}</span>
                                        </a>
                                    </div>
                                    <div className="ec-flex ec-ec-items-center ec-mb-6">
                                        <a className="ec-text-sm ec-flex ec-space-x-2" onClick={handleLocationClick}>
                                            <MarkerIcon/>
                                            <span>{useMyLocation}</span>
                                        </a>
                                        <UIModal open={isModalOpen} onClose={()=> setIsModalOpen(false)} title={intl.formatMessage(
                                            {
                                                id: 'branchMap.enableGeoLocation',
                                                defaultMessage: 'Enable Geo Location'
                                            }
                                        )}>
                                            <FormattedMessage
                                                id="branchMap.enableGeoLocationDescription"
                                                defaultMessage="To use this feature, please enable your browser's location services." />
                                        </UIModal>
                                    </div>
                                </div>}
                        </div>}
                        <Selected/>
                        <Preferred/>
                        <Recent/>
                        <Nearby/>
                    </div>
                </div>
                <div className={'ec-w-full'}>
                    <GoogleMap/>
                </div>
            </div>
        </div>

    )
}, isPropsAreEqual);

Branches.whyDidYouRender = true
