import React from 'react'
import { useIntl } from 'react-intl'
import { useInventoryFinderState } from '@inventoryFinder/state'
import { InventoryFinder } from '@inventoryFinder/components'
import {CurrentBranch} from "./CurrentBranch";
import {DeletedBranch} from "./DeletedBranch/DeletedBranch";

export const BranchLocator = () => {
    const intl = useIntl()

    const {
        isProductInventory,
        isEnabled,
        isOpen,
        setIsOpen,
    } = useInventoryFinderState()

    let branchesLabel = intl.formatMessage({ id: 'branches.header.title', defaultMessage: 'Branches' })

    if (isProductInventory) {
        branchesLabel = intl.formatMessage({
            id: 'branches.product.header.title',
            defaultMessage: 'Product Availability per Branch'
        })
    }

    if (!isEnabled) return <CurrentBranch/>

    return (
        <div>
            <DeletedBranch/>
            <InventoryFinder title={branchesLabel} open={isOpen} onClose={() => setIsOpen(false)}>
                <CurrentBranch/>
            </InventoryFinder>
        </div>
    )
}

BranchLocator.whiDidYouRender = true
