import { useStore } from '@store/hooks'

export const usePunchout = () => {
    const { config } = useStore()

    return {
        isPunchout: !!config.punchout,
        config: config.punchout
    }
}
