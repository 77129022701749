import React from "react";
import { FormattedMessage, useIntl } from 'react-intl'
import {UIButton} from '@ui/components';
import {Right} from './Right';
import {Branch} from '@branch/types';
import { useBranch, useBranchInfo } from '@branch/hooks'
import { Address } from '@branch/components/Branch/Address'
import { Hours } from '@branch/components/Branch/Hours'
import { formatPhoneNumber } from '@utils'
import clsx from "clsx";
import {useCustomerData} from "../../../../Customer";

type BranchDetailsProps = {
    branch: Branch;
    isProductInventory: boolean;
    inventoryData: any;
    isMobile: boolean;
    handleMapLocationClick: (branch: Branch) => void;
    gradientVisibility: boolean;
    setGradientVisibility: (branchId: number) => void;
}

export const BranchDetails = (
    {
        branch,
        isProductInventory,
        inventoryData,
        isMobile,
        handleMapLocationClick,
        gradientVisibility,
        setGradientVisibility
    }: BranchDetailsProps) => {

    const {getBranchName} = useBranch();
    const { canTextBranch } = useBranchInfo()
    const {isCompanyUser} = useCustomerData();
    const intl = useIntl();

    const gradientClass = 'inventory-action ec-flex ec-items-center ec-justify-around ec-z-[2] ec-absolute ec-top-0 ec-left-0 ec-w-full ec-h-full ec-transition-all ec-duration-300 ec-bg-gradient-to-t ec-from-white ec-to-transparent';
    const branchContainerClass = 'ec-relative ec-border-b ec-border-gray-300 ec-mb-4 ec-pb-4 ec-overflow-hidden ec-transition-all ec-duration-300 ec-max-h-[120px]';

    const textToBranchLabel = intl.formatMessage({
        id: 'inventory.finder.text.to.branch',
        defaultMessage: 'Text the branch',
    });

    const availableInventory = intl.formatMessage({
        id: 'inventory.finder.availableInventory',
        defaultMessage: 'Available Inventory:',
    });

    const touchForDetails = intl.formatMessage({
        id: 'inventory.finder.touchForDetails',
        defaultMessage: 'Touch for details',
    });

    const hasParentWithClass = (element, classname) => {
        if (element.classList && element.classList.contains(classname)) {
            return true;
        }
        return element.parentNode && hasParentWithClass(element.parentNode, classname);
    };

    const handleDivClick = (event) => {
        if (!hasParentWithClass(event.target, 'inventory-action')
            && !hasParentWithClass(event.target, 'hours-click')) {
            handleMapLocationClick(branch);
        }
    };

    const handleGradientClick = () => {
        setGradientVisibility(branch.branch_id);
    }

    const branchId = branch.branch_id;
    const inventory = inventoryData?.find(item => item.branch_id === branchId) || null;

    const Qty = ({ item }) => {
        if (isCompanyUser) return item.qty_available

        return item.in_stock
            ? <FormattedMessage id="inventory.standard.yes" defaultMessage="Yes"/>
            : <FormattedMessage id="inventory.standard.no" defaultMessage="No"/>
    }

    return (
        <div onClick={handleDivClick}
             className={!isMobile || gradientVisibility ? 'ec-border-b ec-border-gray-300 ec-mb-4 ec-pb-4' : branchContainerClass}
        >
            {isMobile && <div className={gradientVisibility ? 'ec-hidden' : gradientClass}
                              onClick={handleGradientClick}
            >
                <UIButton size={"small"} variant={"outlined"}>
                    <span>
                        {touchForDetails}
                    </span>
                </UIButton>
            </div>}
            <div className="ec-font-semibold">
                {getBranchName(branch)}
            </div>
            <div className="ec-grid ec-grid-cols-[3fr_2fr] ec-gap-4 ec-mt-2">
                <div>
                    {isProductInventory && inventoryData && <div className={clsx("ec-font-medium ec-py-2", { 'ec-z-[2] ec-relative': isMobile })}>
                        {availableInventory} {isProductInventory && inventoryData && <span
                        className={clsx("ec-font-medium ec-py-2", { 'ec-text-green-inventoryGreen': inventory?.in_stock })}>
                            <Qty item={inventory}/>
                        </span>}
                    </div>}
                    <Address branch={branch} title={null}/>
                    {canTextBranch(branch) &&
                        <div className={'inventory-action'}>
                            <a href={`sms: ${formatPhoneNumber(branch.phone)}`}
                               className="ec-mt-2">
                                {textToBranchLabel}
                            </a>
                        </div>
                    }
                </div>
                <Right branch={branch}/>
                <div className="ec-col-span-full">
                    <Hours branch={branch}/>
                </div>
            </div>
        </div>
    );
};
