import { GraphQlError, request } from '../../../../../models/api'
import { reportProblem } from '../'

export type ReportProblemApiParams = {
    code: string
    type: string
    description: string
    url: string
}

type ReportProblemApiResult = {
    reportProblem: {
        was_reported: boolean
    }
}

export type ReportProblemResult = {
    wasReported: boolean
    errors?: GraphQlError[]
}

export const reportProblemApi = async (
    { code, type, description, url }: ReportProblemApiParams
): Promise<ReportProblemResult> => (
    request<ReportProblemApiResult>(reportProblem, { code, type, description, url }).then(data => ({
        wasReported: data.data?.data?.reportProblem?.was_reported || false,
        errors: data.data?.errors || null
    }))
)
