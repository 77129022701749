import React, {createContext, useContext, useEffect, useState, ReactNode, useRef} from "react";
import {useWindowSize} from "../../../Various";
import { useStore } from '@store/hooks'
import {useAccount} from "../../../Account";
import {MenuIds} from "./userMenu";
import {useCustomerData} from "../../../Customer";
import {useStorageContext} from "../../../Storage";

export type MenuItemType = {
    name: string | ReactNode;
    class: string;
    href: string;
    href_logged_in?: string;
    level: number;
    id: string;
    parentId?: string;
    children?: MenuItemType[];
    links?: MenuItemType[];
    authorization?: string[];
    badges?: string[]
};

type MenuStateType = {
    isOpen: boolean;
    activeLevel: number;
    menuData: {
        children: MenuItemType[],
        brands?: MenuItemType[],
        proGuides?: MenuItemType[],
        aboutUs?: MenuItemType[],
        links?: MenuItemType[]
    };
    currentSubmenu: MenuItemType | null;
    menuStyle: any | null;
    config: ConfigType;
};

type MenuContextType = MenuStateType & {
    setIsOpen: (isOpen: boolean) => void;
    setActiveLevel: (activeLevel: number) => void;
    setCurrentSubmenu: (activeSubmenu: MenuItemType | null) => void;
    shouldHideMenu: (data: MenuItemType, isSubMenuOpen: boolean) => boolean;
    handleClick: (e: React.MouseEvent, data: MenuItemType) => void;
    handleCloseSubmenu: (e: React.MouseEvent) => void;
    currentSubmenuOpened: (data: MenuItemType) => boolean;
    setMenuStyle: (menuStyle: any | null) => void;
}

type ConfigType = {
    menuClass: string;
    overlayId: string | null | false;
}

export interface CustomerMenuPermissions {
    canGetStockingLists: boolean;
    canShareQuicklists: boolean;
    canViewAmanLoyalty: boolean;
    canViewBilltrust: boolean;
    canViewInvoices: boolean;
    canViewProPoints: boolean;
    canViewQuicklists: boolean;
    canViewWarranty: boolean;
    canViewWarrantyClaims: boolean;
    canViewOrders: boolean;
    canViewPrices: boolean;
    canViewCompany: boolean;
    canViewCompanyUsers: boolean;
    canViewCompanyRoles: boolean;
    canViewLinkedAccounts: boolean;
    canViewQuotes: boolean;
    canViewOrdersStatus: boolean;
    canViewOrdersHistory: boolean;
    isEpCustomer: boolean
}

const MenuStateContext = createContext<MenuContextType | undefined>(undefined);

export const MenuProvider = ({children, config, data}) => {
    const {removeAll} = useStorageContext();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [customerPermissions, setCustomerPermissions] = useState<CustomerMenuPermissions | null>(null);
    const [activeLevel, setActiveLevel] = useState<number>(1);
    const [currentSubmenu, setCurrentSubmenu] = useState<MenuItemType | null>(null);
    const [menuData, setMenuData] = useState<{ children: MenuItemType[] }>(data);
    const [menuStyle, setMenuStyle] = useState<any | null>(null);
    const {isMobile} = useWindowSize();
    const originalData = useRef<{ children: MenuItemType[] }>(data);
    const {isRequestStatus, isCompanyUser, customerData} = useCustomerData();
    const { account, accounts } = useAccount()
    const { config: storeConfig } = useStore()
    const bodyRef = useRef(document.body);

    const processNode = (node, parentId = null) => {
        node.parentId = parentId;
        if (node.authorization?.length > 0 && customerPermissions) {
            const hasPermission = node.authorization.some((permission: string) => {
                const isNegativeCheck = permission.startsWith("!");
                const permissionKey = isNegativeCheck ? permission.substring(1) : permission;
                const permissionValue = customerPermissions ? customerPermissions[permissionKey] ?? false : false;

                return isNegativeCheck ? !permissionValue : permissionValue === true;
            });

            !hasPermission ? node.class = `${node.class} ec-hidden` : node.class;
        }

        node.badges = (storeConfig.badges || [])
            .filter(badge => badge.id === node.id)
            .map(badge => badge.badge)

        if (node.children) {
            node.children.forEach(childNode => processNode(childNode, node.id));
        }

        return node;
    };


    const processForMobile = (data) => {
        const mobileData = {...data};

        if(data.brands || data.proGuides || data.aboutUs || data.links) {
            const categoriesNode: MenuItemType = {
                name: 'Categories',
                id: 'categories',
                class: '',
                href: '',
                level: 1,
                children: mobileData.children
            };

            mobileData.children = [categoriesNode];
        }

        if (mobileData.brands) {
            mobileData.children.push({
                name: 'Brand',
                id: 'brands',
                class: '',
                href: '',
                level: 1,
                children: mobileData.brands
            });

            delete mobileData['brands'];
        }

        if (mobileData.proGuides) {
            mobileData.children.push({
                name: 'PRO Guides',
                id: 'proGuides',
                class: '',
                href: '',
                level: 1,
                children: mobileData.proGuides
            });

            delete mobileData['proGuides'];
        }

        if (mobileData.aboutUs) {
            mobileData.children.push({
                name: 'About Us',
                id: 'aboutUs',
                class: '',
                href: '',
                level: 1,
                children: mobileData.aboutUs
            });

            delete mobileData['aboutUs'];
        }

        if (mobileData['links']) {
            mobileData.children = mobileData.children.concat(mobileData['links']);
            delete mobileData['links'];
        }

        processNode(mobileData);

        return mobileData;
    }

    const shouldHideMenu = (data, isSubMenuOpen) => {
        const currentMenuLevel = currentSubmenu?.level ?? 0;
        const sameLevel = currentMenuLevel >= data.level;

        return isSubMenuOpen || sameLevel;
    };

    const handleClick = (e: React.MouseEvent, data: MenuItemType) => {
        if(data.id === MenuIds.SIGN_OUT){
            setActiveLevel(1);
            removeAll();

            setTimeout(() => {
                window.location.href = window.appConfig.customer.logoutUrl;
            }, 200)
        }
        if (data.children && data.children.length > 0) {
            e.preventDefault();
            setCurrentSubmenu(data);
            setActiveLevel(data.level + 1);
        }
    };

    const findNodeById = (id, node) => {
        if (node.id === id) {
            return node;
        } else if (node.children) {
            let result = null;
            for (let i = 0; result === null && i < node.children.length; i++) {
                result = findNodeById(id, node.children[i]);
            }
            return result;
        }
        return null;
    }

    const handleCloseSubmenu = () => {
        if (currentSubmenu && currentSubmenu.parentId !== null) {
            const parentSubmenu = findNodeById(currentSubmenu.parentId, menuData);
            setCurrentSubmenu(parentSubmenu);
            setActiveLevel(activeLevel - 1);
        }
    };

    const currentSubmenuOpened = (data = null) => {
        if (currentSubmenu) {
            return currentSubmenu.id === data.parentId
        }

        return activeLevel === data.level;
    }

    useEffect(() => {
        const resetData = JSON.parse(JSON.stringify(originalData.current));

        if (isMobile) {
            setMenuData(processForMobile(resetData));
        } else {
            setMenuData(processNode(resetData, null));
        }
    }, [isMobile, customerData, account, customerPermissions, accounts]);


    useEffect(() => {
        setCurrentSubmenu(null);
        setActiveLevel(1);
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !event.target.closest(`.${config.menuClass}`)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => document.removeEventListener("click", handleClickOutside);
    }, [isOpen]);

    useEffect(() => {
        if (!config.overlayId) return;

        const overlay = document.getElementById(config.overlayId);

        if (!overlay) return;

        if (isOpen) {
            overlay.classList.remove("ec-hidden");
        } else {
            overlay.classList.add("ec-hidden");
        }

        const overlays = document.querySelectorAll('.overlay-menu');
        const isAnyOverlayVisible = Array.from(overlays).some(overlay => !overlay.classList.contains('ec-hidden'));

        if (isAnyOverlayVisible) {
            bodyRef.current.style.overflow = "hidden";
        } else {
            bodyRef.current.style.overflow = "visible";
        }

    }, [isOpen, config, bodyRef]);

    useEffect(() => {

        if(isOpen && isMobile){
            bodyRef.current.style.overflow = "hidden";
        }

        if(!isOpen && isMobile){
            bodyRef.current.style.overflow = "visible";
        }

    }, [isMobile, isOpen, bodyRef]);

    useEffect(() => {
        const permissions = customerData?.permissions;

        if(!isCompanyUser){
            //@ts-ignore
            setCustomerPermissions({...permissions, isEpCustomer: false})
        }

        if(isRequestStatus){
            //@ts-ignore
            setCustomerPermissions({...permissions, isEpCustomer: false})
        }

        if(isCompanyUser && !isRequestStatus){
            //@ts-ignore
            setCustomerPermissions({...permissions, isEpCustomer: true})
        }

    }, [account, customerData, isOpen, isCompanyUser, isRequestStatus]);

    return (
        <MenuStateContext.Provider value={
            {
                isOpen,
                setIsOpen,
                activeLevel,
                setActiveLevel,
                menuData,
                currentSubmenu,
                setCurrentSubmenu,
                shouldHideMenu,
                handleClick,
                handleCloseSubmenu,
                currentSubmenuOpened,
                setMenuStyle,
                menuStyle,
                config
            }
        }>
            {children}
        </MenuStateContext.Provider>
    );
}

export const useMenuState = () => {
    const context = useContext(MenuStateContext);
    if (context === undefined) {
        throw new Error("useMenuState must be used within a MenuProvider");
    }
    return context;
}
