import React, {memo} from 'react'
import {ThemeProvider} from '../../state'
import {HeaderContainer} from './HeaderContainer'
import {useWindowSize} from '../../../Various'
import {HeaderContainerMobile} from './HeaderContainerMobile'
import {IntlProvider} from "react-intl";
import {Toaster} from '@model/tostify'
import {TestApp} from "./TestApp";
import {AccountProvider} from "../../../Account";
import {StorageProvider} from "../../../Storage";
import {CustomerProvider} from "../../../Customer";
import {BranchProvider, Contact} from "../../../Branch";
import { theme } from '../../config/theme'
import {ReportAProblem} from "../../../Associate";
import {AppProvider, ErrorBoundary} from "../../../App";
import {ErrorMessage} from "../../../Ui";
import {AnalyticsArea, AnalyticsProvider} from "../../../Analytics";
import {MapProvider} from "../../../Map";
import {AssociateReportProblemProvider} from "../../../Associate/";

type HeaderProps = {
    themeConfig: any
}

export const Header = (
    {themeConfig}: HeaderProps
) => {
    const {isMobile} = useWindowSize()

    // @ts-ignore
    const { ThemeProvider: SearchThemeProvider } = window.watsco_search_ui;

    return (
        <div>
            <BranchProvider>
                <StorageProvider>
                    <CustomerProvider>
                        <AccountProvider>
                            <TestApp/>
                        </AccountProvider>
                    </CustomerProvider>
                </StorageProvider>
            </BranchProvider>

            <IntlProvider locale={'en'}>
                <ThemeProvider config={themeConfig}>
                    <SearchThemeProvider theme={theme}>
                        <div>
                            {!isMobile && <HeaderContainer/>}
                            {isMobile && <HeaderContainerMobile/>}
                        </div>
                    </SearchThemeProvider>
                </ThemeProvider>
                <Toaster/>
                <ErrorBoundary fallback={<ErrorMessage section/>}>
                    <AppProvider>
                        <AnalyticsProvider area={AnalyticsArea.Default}>
                            <MapProvider>
                                <Contact/>
                                <AssociateReportProblemProvider>
                                    <ReportAProblem/>
                                </AssociateReportProblemProvider>
                            </MapProvider>
                        </AnalyticsProvider>
                    </AppProvider>
                </ErrorBoundary>
            </IntlProvider>
        </div>
    )
}
