import React, {useState, useEffect, useMemo} from "react";
import {UIButton, UIModal, UISelect} from "../../../../../Ui";
import {useCustomerData} from "../../../../../Customer";
import {FormattedMessage} from "react-intl";
import { Branch, useBranchData } from '../../../../../Branch'
import {StorageKeys, useStorageContext} from "../../../../../Storage";

const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const kmDistance = R * c;
    return kmDistance * 0.621371;
};

type BranchWithDistance = Branch & {
    distance?: number
}

//@todo: move to a config file
const paducahCoordinates = {
    latitude: 37.0841709,
    longitude: -88.5980283
};

export const DeletedBranch = () => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(true);
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [isBranchesLoading, setIsBranchesLoading] = useState<boolean>(false);
    const customerData = useCustomerData();
    const {branches, switchPreferredBranch, switchCurrentBranch, invalidateData, getBranchName} = useBranchData();
    const {removeByKey} = useStorageContext();

    const branchInfo = customerData?.customerBranches?.branch_deletion_info;
    let deletedBranch = branches?.find(b => branchInfo?.deleted_branch_id && b.branch_id === branchInfo.deleted_branch_id);

    if (deletedBranch) {
        deletedBranch = {
            ...deletedBranch,
            latitude: paducahCoordinates.latitude,
            longitude: paducahCoordinates.longitude
        };
    }

    const sortedBranches = useMemo<BranchWithDistance[]>(() => {
        if (!branches) return [];

        const sourceLat = deletedBranch?.latitude || paducahCoordinates.latitude;
        const sourceLng = deletedBranch?.longitude || paducahCoordinates.longitude;

        return [...branches]
            .filter(b => b.branch_id !== branchInfo?.deleted_branch_id)
            .map(branch => ({
                ...branch,
                distance: calculateDistance(
                    sourceLat,
                    sourceLng,
                    branch.latitude,
                    branch.longitude
                )
            }))
            .sort((a, b) => a.distance - b.distance);
    }, [branches, deletedBranch, branchInfo]);

    const branchOptions = sortedBranches.map(branch => ({
        value: branch.branch_id.toString(),
        label: `#${branch.branch_id} - ${branch.branch_name} (${Math.round(branch.distance)} mi)`
    }));

    useEffect(() => {
        if (sortedBranches?.length > 0 && !selectedBranchId) {
            setSelectedBranchId(sortedBranches[0].branch_id.toString());
        }
    }, [sortedBranches, selectedBranchId]);

    if (!branchInfo?.replacement_branch_id) {
        return null;
    }

    const handleClose = () => {
        try {
            setIsBranchesLoading(true)
            removeByKey(StorageKeys.CUSTOMER)

            const branchIdToUse = selectedBranchId || branchInfo.replacement_branch_id
            const params = {
                branchId: branchIdToUse.toString(),
                customerReload: false
            }

            switchCurrentBranch.mutateAsync(params).then(() => {
                switchPreferredBranch.mutateAsync(params).then(() => {
                    invalidateData()
                })
            })
        } catch (error) {
            console.error('Error switching branches:', error)
        }
    }

    const handleBranchChange = (e) => {
        setSelectedBranchId(e.target.value);
    };

    return (
        <UIModal
            open={isOpenModal}
            onClose={()=> setIsOpenModal(false)}
            title={<FormattedMessage
                id="branch.deleted_branch.modal.title"
                defaultMessage="Important Branch Update"
            />}
        >
            <div className="ec-p-6">
                <p className="ec-mb-4">{branchInfo?.message}</p>
                <div className="ec-grid ec-grid-cols-1 ec-p-4 ec-rounded ec-mb-4">
                    <div className={'ec-bg-gray-50 ec-p-4 ec-mb-4'}>
                        <span className="ec-text-sm ec-text-gray-500 ec-pr-2">
                            <FormattedMessage
                                id="branch.deleted_branch.modal.previous_branch"
                                defaultMessage="The Branch"
                            />
                        </span>
                        <span className="ec-font-medium ec-pr-2">
                            <FormattedMessage
                                id="branch.deleted_branch.modal.branch_number"
                                defaultMessage="#{number}"
                                values={{number: branchInfo?.deleted_branch_id}}
                            />
                        </span>
                        {deletedBranch?.branch_name && (
                            <span className="ec-text-sm ec-text-gray-600 ec-pr-2">{deletedBranch?.branch_name}</span>
                        )}
                        <span className={'ec-pr-2'}>
                            <FormattedMessage
                                id="branch.deleted_branch.modal.second-part"
                                defaultMessage="is closed!"
                            />
                        </span>
                    </div>
                    <div className={'ec-p-4 ec-bg-gray-50'}>
                        <p className="ec-text-sm ec-text-gray-500 ec-mb-2">
                            <FormattedMessage
                                id="branch.deleted_branch.modal.new_branch"
                                defaultMessage="Select preferred Branch"
                            />
                        </p>
                        <UISelect
                            options={branchOptions}
                            value={selectedBranchId ?? ''}
                            onChange={handleBranchChange}
                            disabled={branchOptions?.length === 0}
                            size="large"
                        />
                    </div>
                </div>

                <div>
                    <div className={'ec-mb-4'}>
                        <FormattedMessage
                            id="branch.deleted_branch.modal.message"
                            defaultMessage="If you do not update the preferred branch, you will see the branch update info window again. To update the branch select one and click the button below."
                        />
                    </div>
                </div>

                <div className="ec-flex ec-justify-end">
                    <div className={'ec-px-2'}/>
                    <UIButton onClick={handleClose} variant={'contained'}>
                        {isBranchesLoading && <FormattedMessage
                            id="branch.deleted_branch.modal.button.updating"
                            defaultMessage="Updating..."
                        />}
                        {!isBranchesLoading && <FormattedMessage
                            id="branch.deleted_branch.modal.button"
                            defaultMessage="Update branch"
                        />}
                    </UIButton>
                </div>
            </div>
        </UIModal>
    );
};
