import React, { createContext, useContext, PropsWithChildren } from 'react'
import { shareActiveCartApi, ShareActiveCartParams, ShareActiveCartResult } from '../api'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { CustomerProvider } from '@customer/context'
import { usePermission } from '@customer/hooks'
import { shareSaveCartApi, ShareSaveCartParams, ShareSaveCartResult } from '../api/shareSaveCartApi'
import { SaveCart } from '@saveCart/types'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'
import { CartProvider } from '@checkout/context'
import { useCart } from '@checkout/hooks'

export type ShareCartProviderState = {
    shareActiveCartMutation: UseMutationResult<ShareActiveCartResult, unknown, ShareActiveCartParams>
    shareSaveCartMutation: UseMutationResult<ShareSaveCartResult, unknown, Omit<ShareSaveCartParams, 'saveCartId'>>
    isAllowed: boolean
    saveCart: SaveCart
}

type ShareCartProviderProps = {
    saveCart?: SaveCart
}

const ShareCartContext = createContext<ShareCartProviderState>(null)

export const ShareCartProvider = (
    { saveCart, children }: PropsWithChildren<ShareCartProviderProps>
) => (
    <CustomerProvider>
        <CartProvider>
            <ShareCartProviderInner saveCart={saveCart}>
                {children}
            </ShareCartProviderInner>
        </CartProvider>
    </CustomerProvider>
)

const ShareCartProviderInner = (
    { saveCart, children }: PropsWithChildren<ShareCartProviderProps>
) => {
    const { isAllowed } = usePermission()
    const { reloadCart } = useCart()

    const shareActiveCartMutation = useMutation({
        mutationFn: (params: ShareActiveCartParams) => {
            if (!isAllowed('shareCart')) throw Error('Unauthorized to share cart')
            return shareActiveCartApi(params)
        },
        onSuccess: () => {
            void reloadCart()

            toast.success(
                <FormattedMessage id="share.cart.save.success"
                                  defaultMessage="Current cart has been shared"/>)
        },
        onError: () => {
            toast.success(
                <FormattedMessage id="share.cart.active.error"
                                  defaultMessage="Current cart could not be shared"/>)
        }
    })

    const shareSaveCartMutation = useMutation({
        mutationFn: (params: Omit<ShareSaveCartParams, 'saveCartId'>) => {
            if (!isAllowed('shareCart')) throw Error('Unauthorized to share cart')
            return shareSaveCartApi({ ...params, saveCartId: saveCart.save_cart_id })
        },
        onSuccess: () => {
            toast.success(<FormattedMessage id="share.cart.save.success"
                                            defaultMessage="Saved cart has been shared"/>)
        },
        onError: () => {
            toast.success(<FormattedMessage id="share.cart.save.error"
                                            defaultMessage="Saved cart could not be shared"/>)
        }
    })

    return (
        <ShareCartContext.Provider value={{
            shareActiveCartMutation,
            shareSaveCartMutation,
            isAllowed: isAllowed('shareCart'),
            saveCart
        }}>
            {children}
        </ShareCartContext.Provider>
    )
}

export const useShareCart = () => {
    const context = useContext(ShareCartContext)

    if (!context) {
        console.warn('useShareCart must be used within the ShareCartProvider')
    }
    return context
}
