import React, { createContext, PropsWithChildren, useContext } from 'react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'
import { reportProblemApi, ReportProblemResult } from '../../api'

export type ReportProblemProps = {
    code: string
    type: string
    description: string
    url: string
}

type AssociateReportProblemStateType = {
    reportProblem: UseMutationResult<ReportProblemResult, unknown, ReportProblemProps>
}

const AssociateReportProblemStateContext = createContext<AssociateReportProblemStateType>(null);

export const AssociateReportProblemProvider = ({ children }: PropsWithChildren) => {

    const reportProblem = useMutation({
        mutationFn: async ({ code, type, description, url }: ReportProblemProps) => (
            reportProblemApi({ code, type, description, url })
        ),

        onSuccess: ({ wasReported, errors }) => {
            if (errors) {
                errors.forEach(error => toast.error(error.message))
                return
            }

            if (wasReported) {
                toast.success(<FormattedMessage id="reportProblem.success" defaultMessage="Problem reported successfully" />)
            } else {
                toast.error(<FormattedMessage id="reportProblem.error" defaultMessage="Failed to report problem" />)
            }
        },
        onError: (error) => {
            console.error('API error', error)
            toast.error(<FormattedMessage id="reportProblem.error" defaultMessage="Failed to report problem" />)
        }
    })

    return (
        <AssociateReportProblemStateContext.Provider value={{
            reportProblem
        }}>
            {children}
        </AssociateReportProblemStateContext.Provider>
    )
}

export const useAssociateReportProblemState = () => {
    const context = useContext(AssociateReportProblemStateContext)

    if (!context) {
        throw new Error('useAssociateReportProblemState must be used within AssociateReportProblemProvider')
    }

    return context
}
