import React, { PropsWithChildren, useEffect } from 'react'
import { MenuItem } from '../../Elements/MenuItem';
import {useMenuState, useThemeState} from '../../../../../state';
import { UserMenuStyle } from "../../../../../state/context/UserMenuStyle";
import { useIntl } from "react-intl";
import { AccountJob } from "./AccountJob";
import { cn } from "@utils";
import { useAccount } from '@account/hooks'
import { AccountIcon } from '../../../../../icons'
import { UIChevronDownIcon } from '@ui/icons'
import { getLoginUrl, useCustomerData } from '../../../../../../Customer'

export const MenuDesktop = () => {
    const { menuData, isOpen, setMenuStyle, menuStyle, config, setIsOpen,  } = useMenuState();
    const intl = useIntl()
    const {isCheckout} = useThemeState();
    const { account: accountData, accounts } = useAccount();
    const {isLoggedIn, isCustomerLoading, customerData, customer} = useCustomerData();

    let accountMenu = intl.formatMessage({ id: 'account.header.top', defaultMessage: 'Your Account' });
    const loading = intl.formatMessage({id: 'branch.header.top.mobile.loading', defaultMessage: 'Loading...'});
    let account = accountData?.customer_id ? accountData?.customer_id : '';

    if(isCustomerLoading && customer === undefined) {
        account = loading;
    }

    if(!isLoggedIn && isCustomerLoading && customer !== undefined) {
        accountMenu = intl.formatMessage({id: 'account.header.top.guest', defaultMessage: 'Sign In or'});
        account = intl.formatMessage({
            id: 'account.header.top.guest.create',
            defaultMessage: 'Create an Account'
        });
    }

    if(!isLoggedIn && !isCustomerLoading && !customerData?.customer?.firstname) {
        accountMenu = intl.formatMessage({id: 'account.header.top.guest', defaultMessage: 'Sign In or'});
        account = intl.formatMessage({
            id: 'account.header.top.guest.create',
            defaultMessage: 'Create an Account'
        });
    }

    useEffect(() => {
        setMenuStyle(UserMenuStyle);
    }, [])

    if (!menuStyle) return null;

    const LoginWrapper = ({ children }: PropsWithChildren) => {
        const className = cn(
            'hover:ec-no-underline ec-overflow-visible ec-w-28 ec-h-7',
            config.menuClass,
            'ec-cursor-pointer'
        )

        return isLoggedIn
            ? <div className={className}>{children}</div>
            : <a href={getLoginUrl().href} className={className}>{children}</a>
    }

    return (
        <LoginWrapper>
            <div
                className={cn('ec-z-10 ec-relative ec-w-36 ec-flex ec-items-center')}
                onClick={() => {
                    if(isLoggedIn){
                        setIsOpen(!isOpen)
                    }
                }}>
                <AccountIcon/>
                <div>
                    <div className={cn("ec-text-white ec-text-xs")}>{accountMenu}</div>
                    <div className={cn("ec-text-white ec-flex ec-text-s", { 'ec-text-xs': !isLoggedIn})}>
                        {account} {!accountData?.customer_id && customerData?.customer?.firstname && <span>{customerData.customer.firstname}</span>}
                        {account && isLoggedIn &&
                            <div className="ec-pl-1">
                                <UIChevronDownIcon size="small" stroke="semibold"/>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {isOpen && (
                <div className={cn("ec-overflow-visible ec-w-28 ec-h-7", config.menuClass)}>
                    <ul className={cn("ec-overflow-y-auto ec-overflow-x-hidden ec-w-64 ec-mt-4 ec--ml-[100%] relative ec-z-[22]",
                        "ec-max-h-[calc(100vh-125px)] ec-h-auto",
                        menuStyle.slide, isOpen ? menuStyle.open : menuStyle.close)}>
                        {accountData?.customer_id && account && isOpen && !isCheckout &&  (
                            <li className={cn('ec-w-64 ec-bg-white ec-p-4 ec-border-b ec-border-solid ec-border-gray-400')}>
                                <AccountJob />
                            </li>
                        )}
                        {menuData.children.map((category, index) => (
                            <MenuItem key={index} data={category} />
                        ))}
                    </ul>
                </div>
            )}
        </LoginWrapper>
    )
}

MenuDesktop.whyDidYouRender = true
