import { useCustomerData } from '../../context'
import { CustomerPermissions } from '../../types'
import { useStore } from '@store/hooks'

export const usePermission = () => {
    const { customerData } = useCustomerData();
    const { config } = useStore()

    const isAllowed = (resource: keyof CustomerPermissions) => {
        const allowed = customerData?.permissions[resource] || false

        switch (resource) {
            case 'saveCart':
            case 'shareCart':
            case 'proposal':
                return allowed && !config.punchout
            default:
                return allowed
        }
    }

    return {
        isAllowed
    }
}
