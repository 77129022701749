import React, {createContext, useContext, useCallback, useState, useEffect, useMemo} from 'react';
import useLocalStorage from 'use-local-storage';
import { BranchesResult } from '@branch/api'
import { AccountResult } from '@account/api'
import { CustomerResult } from '@customer/api'
import { CartResult } from '@checkout/api'
import { StoreConfigResult } from '@store/api'
import { getDataLocalStorageKey } from '@utils'

const KEY = getDataLocalStorageKey();

export enum StorageKeys {
    STORE = 'store',
    CUSTOMER = 'customer',
    BRANCH = 'branch',
    ACCOUNT = 'account',
    CART = 'cart',
}

interface StorageContextType {
    setKeyData: (data: any) => void;
    removeAll: () => void;
    cleanLocalStorage: () => void;
    removeByKey: (key: StorageKeys) => void;
    cartStorage: CartResult;
    storeStorage: StoreConfigResult;
    customerStorage: CustomerResult;
    branchStorage: BranchesResult;
    accountStorage: AccountResult;
}

const StorageContext = createContext<StorageContextType | null>(null);

export const StorageProvider = ({ children }) => {
    const [localStorageData, setLocalStorageData] = useLocalStorage(KEY, {});
    const [keyData, setKeyData] = useState({});
    const [hasExecuted, setHasExecuted] = useState<Set<string>>(new Set());

    const cartStorage = useMemo(() => localStorageData?.[StorageKeys.CART],
        [JSON.stringify(localStorageData?.[StorageKeys.CART])]
    );

    const storeStorage = useMemo(() => localStorageData?.[StorageKeys.STORE],
        [JSON.stringify(localStorageData?.[StorageKeys.STORE])]
    );

    const customerStorage = useMemo(() => localStorageData?.[StorageKeys.CUSTOMER],
        [JSON.stringify(localStorageData?.[StorageKeys.CUSTOMER])]
    );

    const branchStorage = useMemo(() => localStorageData?.[StorageKeys.BRANCH],
        [JSON.stringify(localStorageData?.[StorageKeys.BRANCH])]
    );

    const accountStorage = useMemo(() => localStorageData?.[StorageKeys.ACCOUNT],
        [JSON.stringify(localStorageData?.[StorageKeys.ACCOUNT])]
    );

    useEffect(() => {
        if(!localStorageData) {
            setLocalStorageData({});
        }
    }, [localStorageData]);

    useEffect(() => {
        const hasChanges = Object.entries(keyData).some(([key, value]) => {
            return localStorageData && localStorageData[key] !== value;
        });

        if (hasChanges) {
            setLocalStorageData(prevData => ({ ...prevData, ...keyData }));
            // console.debug('setLocalStorageData', keyData);
        }
    }, [keyData]);

    const removeAll = useCallback(() => {
        const operationKey = 'removeAll';
        if (!hasExecuted.has(operationKey)) {
            setLocalStorageData({});
            setKeyData({});
            setHasExecuted(prev => new Set(prev).add(operationKey));
        }
    }, [hasExecuted]);

    const cleanLocalStorage = useCallback(() => {
        const operationKey = 'cleanStorage';
        if (!hasExecuted.has(operationKey)) {

            const updatedData = { ...localStorageData };

            setLocalStorageData(updatedData);
            setHasExecuted(prev => new Set(prev).add(operationKey));
        }
    }, [hasExecuted]);

    const removeByKey = useCallback((key: StorageKeys) => {
        const operationKey = `remove_${key}`;
        if (!hasExecuted.has(operationKey)) {
            setLocalStorageData(prevData => {
                const newData = { ...prevData };
                delete newData[key];
                return newData;
            });

            setHasExecuted(prev => new Set(prev).add(operationKey));
        }
    }, [hasExecuted]);

    return (
        <StorageContext.Provider
            value={{
                setKeyData,
                removeAll,
                cleanLocalStorage,
                removeByKey,
                cartStorage,
                storeStorage,
                customerStorage,
                branchStorage,
                accountStorage,
            }}
        >
            {children}
        </StorageContext.Provider>
    );
};

export const useStorageContext = () => {
    const context = useContext(StorageContext);

    if (!context) {
        throw new Error('useStorageContext must be used within a StorageProvider');
    }

    return context;
};
