import React, {memo} from 'react'
import {cn, isPropsAreEqual} from '@utils'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import {UIAlert, UIButtonLink} from '@ui/components'
import { MiniCartSkeleton } from './Skeleton/MiniCartSkeleton'
import { MiniCartItemsSkeleton } from './Skeleton/MiniCartItemsSkeleton'
import { Button as CreateProposalButton } from '@proposal/components'
import { useAccount } from '@account/hooks'
import { MiniCartItem } from './MiniCartItem'
import clsx from 'clsx'
import { usePermission } from '@customer/hooks'
import { SaveCart } from './SaveCart'
import { ShareCart } from './ShareCart'
import { ProposalActionProvider } from '@proposal/context'
import { useCart } from '../../hooks'
import { CartActionsProvider } from '../../context'
import { usePunchout } from '@punchout/hooks'

export const MiniCart = memo(() => {
    const { cart, isLoading } = useCart();
    const { isAllowed } = usePermission()
    const { account } = useAccount()
    const { isPunchout, config: punchoutConfig } = usePunchout()
    const intl = useIntl();

    const customerCanViewPrices = isAllowed('canViewPrices');
    const canViewPrices = customerCanViewPrices
        && cart?.prices?.grand_total?.value !== null
        && cart?.prices?.grand_total?.value !== undefined;

    const showPricingIssue = (cart?.prices?.grand_total?.value === null || cart?.prices?.grand_total?.value === undefined)
        && customerCanViewPrices;

    if (!cart || isLoading) {
            return (
                <div>
                    <MiniCartSkeleton/>
                    {Array(5).fill(0).map((_, index) => (
                        <MiniCartItemsSkeleton key={index}/>
                    ))}
                </div>
            )
        }

    if(cart.items.length === 0) {
        return (
            <div className={cn('ec-flex', 'ec-flex-col', 'ec-items-center', 'ec-justify-center', 'ec-p-6')}>
                <div className={cn('ec-text-center', 'ec-text-2xl', 'ec-font-bold', 'ec-mb-2')}>
                    {intl.formatMessage({
                        id: "cart.empty",
                        defaultMessage: "Your cart is empty"
                    })}
                </div>
                <div className={cn('ec-text-center', 'ec-text-sm')}>
                    {intl.formatMessage({
                        id: "cart.empty.description",
                        defaultMessage: "You have no items in your shopping cart."
                    })}
                </div>
            </div>
        )
    }

    return (
        <div>
            {canViewPrices && <div className={cn('ec-flex', 'ec-justify-end', 'ec-items-right', 'ec-mb-6', 'ec-relative')}>
                <span className={cn('ec-mr-2')}>
                     {intl.formatMessage({
                         id: "cart.subtotal",
                         defaultMessage: "Subtotal:"
                     })}
                </span>

                    <strong>
                        {cart.prices.grand_total.value > 0
                            ? <FormattedNumber style="currency"
                                               value={cart.prices.grand_total.value}
                                               currency={cart.prices.grand_total.currency}/>
                            : '-'
                        }
                    </strong>
                </div>}
            {showPricingIssue && <div className={'ec-w-full ec-py-2'}>
                <UIAlert type={'warning'}>
                    {intl.formatMessage({
                        id: "cart.issue.conduit",
                        defaultMessage: "We are experiencing pricing issues."
                    })}
                </UIAlert>
            </div>}
            <div className={'ec-flex ec-w-full ec-justify-between ec-mb-6'}>
                <div className={'ec-w-24'}>
                    <FormattedMessage id="account.mini.cart" defaultMessage="Account #"/>
                </div>
                {account?.customer_id && <div className={'ec-w-full'}>
                    {account.customer_id} {account.customer_name}
                </div>}
            </div>
            <div className={'ec-mb-2 ec-border-b ec-border-solid ec-border-gray-400'}>
                <div className={'ec-grid ec-grid-cols-2 ec-gap-4 ec-mb-4 '}>
                    <div className={clsx({ 'ec-col-span-2': !canViewPrices })}>
                        <UIButtonLink href={'/checkout/cart'}
                                      variant={"outlined"}
                                      app={false}
                                      size={'large'}
                                      fullWidth={true}>
                            <span>
                                {intl.formatMessage({ id: "cart.view-cart", defaultMessage: "View Cart" })}
                            </span>
                        </UIButtonLink>
                    </div>
                    {canViewPrices &&
                        <UIButtonLink href={!isPunchout ? '/checkout' : punchoutConfig.checkoutUrl}
                                      variant={"contained"}
                                      app={false} size={'large'}
                                      fullWidth={true}>
                            <span>{intl.formatMessage({ id: "cart.checkout", defaultMessage: "Checkout" })}</span>
                        </UIButtonLink>
                    }
                    {isPunchout &&
                        <div className="ec-col-span-full *:ec-w-full">
                            <UIButtonLink href={punchoutConfig.cancelUrl} variant="text" app={false}>
                                <FormattedMessage id="punchout.cancel" defaultMessage="Cancel & Return"/>
                            </UIButtonLink>
                        </div>
                    }
                    <SaveCart/>
                    <ShareCart/>
                </div>
                {isAllowed('proposal') &&
                    <div className="ec-border-t ec-border-gray-400 ec-py-4">
                        <ProposalActionProvider>
                            <CreateProposalButton/>
                        </ProposalActionProvider>
                    </div>
                }
            </div>

            <CartActionsProvider>
                {cart.items.map(item =>
                    <MiniCartItem key={item.uid} item={item}/>
                )}
            </CartActionsProvider>
        </div>
    );
}, isPropsAreEqual);

MiniCart.whyDidYouRender = true;
